import React from "react"
import { useParams } from "react-router-dom"
import {
  Button,
  Table,
  Thead,
  Tbody,
  Td,
  Tr,
  TableContainer,
  Box,
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import { Datasets, Schemas, DatasetSchemas } from "../lib/store"
import Link from "./Link"

export default function DatasetDetail() {
  // Global state
  const [datasets] = Datasets.useState()
  const [schemas] = Schemas.useState()
  const [datasetSchemas] = DatasetSchemas.useState()

  // Path params
  const { datasetId } = useParams()

  // What to display
  const [dataset, setDataset] = React.useState({})
  const [schemasList, setSchemasList] = React.useState([])

  // Pick the dataset and the schemas that belong to it
  React.useEffect(() => {
    setDataset(datasets[datasetId])
    setSchemasList((datasetSchemas[datasetId] || []).map((id) => schemas[id]))
  }, [datasetId, datasets, schemas, datasetSchemas])

  console.debug("Rendering DatasetDetail", {
    datasetId,
    dataset,
    schemasList,
    datasets,
    schemas,
    datasetSchemas,
  })

  return (
    <Box padding="1em" mt="3em">
      <Box display="flex" flexDir="row" justifyContent="flex-end">
        <Link to={`/datasets/${datasetId}/schemas/create`}>
          <Button
            variant="primary"
            rightIcon={<AddIcon />}
            id="btn-schema-new"
            title="Create a schema"
            size="sm"
          >
            Create Schema
          </Button>
        </Link>
      </Box>
      <TableContainer
        mt="1em"
        borderRadius="0.5em"
        boxShadow="3px 3px 10px gray"
        maxHeight={`calc(100vh - 11.5em)`}
        overflowY="auto"
      >
        <Table variant="standard">
          <Thead>
            <Tr>
              <Td>Schema</Td>
              <Td>Fields</Td>
            </Tr>
          </Thead>
          <Tbody>
            {schemasList.map((row, index) => (
              <Tr key={index}>
                <Td>
                  <Link
                    to={`/datasets/${datasetId}/schemas/${row.id}`}
                    variant="underline"
                    textDecoration="underline"
                  >
                    {row.name}
                  </Link>
                </Td>
                <Td>
                  {(row.schema?.properties &&
                    Object.keys(row.schema.properties).length) ||
                    "Unknown"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
