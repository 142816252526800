import React from "react"
import { HStack } from "@chakra-ui/react"

export default function AccountLayout({ children }) {
  return (
    <HStack
      id="layout"
      display="flex"
      overflowY="scroll"
      position="relative"
      height="100vh"
      width="100vw"
    >
      {children}
    </HStack>
  )
}
