const Texts = {
  baseStyle: {
    color: "black",
    fontFamily: "body",
  },
  variants: {
    subtext: {
      color: "white",
      fontWeight: "subtextWeight",
      opacity: 0.75,
    },
    card: {
      color: "black",
      fontSize: "0.9em",
    },
    register: {
      color: "black",
      fontSize: "3vh",
      fontStyle: "italic",
    },
  },
}

export default Texts
