import React from "react"
import OrganizationCreate from "@/components/OrganizationCreate"
import AccountLayout from "@/components/AccountLayout"
import { Text } from "@chakra-ui/react"
import Message from "@/components/Message"

export default function OrganizationCreateView() {
  return (
    <AccountLayout>
      <OrganizationCreate />
      <Message>
        <Text fontSize="2.5vh">
          You{"'"}ll need to create an organization to continue. Organizations
          are used to manage your data and users in Blind Insight.
        </Text>
      </Message>
    </AccountLayout>
  )
}
