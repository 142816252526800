import Buttons from "./variants/Buttons"
import Checkboxes from "./variants/Checkboxes"
import Headings from "./variants/Headings"
import Inputs from "./variants/Inputs"
import Modals from "./variants/Modals"
import Texts from "./variants/Texts"
import Tabs from "./variants/Tabs"
import Accordions from "./variants/Accordions"
import Containers from "./variants/Containers"
import Tables from "./variants/Tables"
import Alerts from "./variants/Alerts"
import Selects from "./variants/Selects"

const Components = {
  Accordion: Accordions,
  Alert: Alerts,
  Button: Buttons,
  Checkbox: Checkboxes,
  Container: Containers,
  Heading: Headings,
  Input: Inputs,
  Modal: Modals,
  Select: Selects,
  Tabs: Tabs,
  Text: Texts,
  Table: Tables,
}

export default Components
