import React from "react"
import { Icon } from "@chakra-ui/react"

function IconLock(props) {
  const encrypted = props.encrypted

  return (
    <Icon viewBox="0 0 24 24" boxSize="1em" {...props}>
      {encrypted ? (
        <path
          fill={props.locked}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99994 0C5.54534 0 3.55549 2.01472 3.55549 4.5V4.51559C2.17469 4.55772 1.35109 4.71365 0.781049 5.29081C0 6.08162 0 7.35442 0 9.9V10.8C0 14.1941 0 15.8912 1.0414 16.9456C2.0828 18 3.7589 18 7.11111 18H8.88889C12.2411 18 13.9172 18 14.9586 16.9456C16 15.8912 16 14.1941 16 10.8V9.9C16 7.35442 16 6.08162 15.219 5.29081C14.6489 4.71363 13.8253 4.55771 12.4444 4.51559V4.5C12.4444 2.01472 10.4545 0 7.99994 0ZM10.6666 4.5C10.6666 3.00883 9.4727 1.8 7.99994 1.8C6.52718 1.8 5.33327 3.00883 5.33327 4.5C5.33329 4.5 5.33331 4.5 5.33333 4.5H10.6666ZM8.88889 10.8C8.88889 11.2971 8.49092 11.7 8 11.7C7.50908 11.7 7.11111 11.2971 7.11111 10.8C7.11111 10.3029 7.50908 9.9 8 9.9C8.49092 9.9 8.88889 10.3029 8.88889 10.8ZM8.88889 13.3464C9.92461 12.9757 10.6667 11.9756 10.6667 10.8C10.6667 9.30883 9.47276 8.1 8 8.1C6.52724 8.1 5.33333 9.30883 5.33333 10.8C5.33333 11.9756 6.07538 12.9757 7.11111 13.3464V15.3H8.88889V13.3464Z"
        />
      ) : (
        <path
          fill={props.unlocked}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3589 0.933058C8.22648 -0.451527 5.40403 -0.28087 3.46493 1.34989L2.68024 2.00981C2.30914 2.3219 2.26884 2.86704 2.59022 3.22741C2.9116 3.58779 3.47297 3.62693 3.84407 3.31483L4.62876 2.65492C5.96256 1.5332 7.90399 1.41581 9.37078 2.3682L9.70299 2.5839C10.5711 3.14758 11.1005 4.06696 11.1621 5.05231C11.0026 5.05207 10.8375 5.05207 10.6667 5.05207H5.33333C2.81918 5.05207 1.5621 5.05207 0.781049 5.81054C0 6.56901 0 7.78975 0 10.2312V11.0944C0 14.3497 0 15.9774 1.0414 16.9887C2.0828 18 3.7589 18 7.11111 18H8.88889C12.2411 18 13.9172 18 14.9586 16.9887C16 15.9774 16 14.3497 16 11.0944V10.2312C16 7.78975 16 6.56901 15.219 5.81054C14.7212 5.32716 14.0301 5.15184 12.9434 5.08826C12.8917 3.519 12.0684 2.04302 10.6911 1.14876L10.3589 0.933058ZM8.88889 11.0944C8.88889 11.5712 8.49092 11.9576 8 11.9576C7.50908 11.9576 7.11111 11.5712 7.11111 11.0944C7.11111 10.6177 7.50908 10.2312 8 10.2312C8.49092 10.2312 8.88889 10.6177 8.88889 11.0944ZM8.88889 13.5367C9.92461 13.1812 10.6667 12.2219 10.6667 11.0944C10.6667 9.66424 9.47276 8.50484 8 8.50484C6.52724 8.50484 5.33333 9.66424 5.33333 11.0944C5.33333 12.2219 6.07538 13.1812 7.11111 13.5367V15.4104H8.88889V13.5367Z"
        />
      )}
    </Icon>
  )
}

export default IconLock
