import React from "react"
import { Flex, VStack } from "@chakra-ui/react"

export default function Message({ children }) {
  return (
    <Flex
      height="100vh"
      width="55vw"
      bg="white"
      alignItems="center"
      justifyContent="center"
    >
      <VStack
        minWidth="60%"
        maxWidth="70%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {children}
      </VStack>
    </Flex>
  )
}
