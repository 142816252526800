import React, { useMemo } from "react"
import { useRouteLoaderData, useParams } from "react-router-dom"
import {
  Grid,
  Box,
  TableContainer,
  Table,
  Thead,
  Th,
  Tr,
  Td,
  Tbody,
  Alert,
} from "@chakra-ui/react"
import { Schemas } from "../lib/store"

export default function SchemaDetail() {
  const user = useRouteLoaderData("auth")
  const [schemas] = Schemas.useState()
  const { schemaId } = useParams()
  // Find the selected schema
  const schema = useMemo(() => schemas[schemaId], [schemas, schemaId])
  // Get the schema properties
  const properties = useMemo(() => schema?.schema?.properties || {}, [schema])
  // Render table rows
  const rows = useMemo(() => {
    const entries = Object.entries(properties)
    if (!entries.length) return []
    return entries.map(([name, value]) => {
      let allowed = value.enum || ["Any"]
      allowed = allowed.map((v) => (
        <Grid key={v} xs={2}>
          {v}
        </Grid>
      ))
      allowed = allowed ? <Grid columns={10}>{allowed}</Grid> : null
      const required = schema?.schema?.required?.includes(name) ? "Yes" : "No"

      return (
        <Tr
          key={name}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <Td>{name}</Td>
          <Td>{value.type}</Td>
          <Td>{required}</Td>
          <Td>{allowed}</Td>
        </Tr>
      )
    })
  }, [schema, properties])

  // Render nothing if we don't have a user
  if (!user) return null
  // Render a not found if we couldn't find a schema in state
  if (!schema)
    return (
      <Box>
        <Alert severity="error">Schema not found</Alert>
      </Box>
    )

  return (
    <Box>
      <Box>{schema.description}</Box>
      <TableContainer
        mt="1em"
        borderRadius="0.5em"
        boxShadow="3px 3px 10px gray"
        maxHeight={`calc(100vh - 11.5em)`}
        overflowY="auto"
      >
        <Table variant="standard">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Required</Th>
              <Th>Allowed Values</Th>
            </Tr>
          </Thead>
          <Tbody>{rows}</Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
