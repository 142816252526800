const GlobalStyles = {
  global: {
    "&::-webkit-scrollbar": {
      width: "2",
      borderRadius: "8px",
      backgroundColor: `transparent`,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "lg",
      backgroundColor: `Secondary.100`,
    },
    ".resizer": {
      position: "absolute",
      right: 0,
      top: 0,
      height: "100%",
      width: "3px",
      background: "rgba(0, 0, 0, 0.5)",
      cursor: "col-resize",
      userSelect: "none",
      touchAction: "none",
    },
    ".resizer.isResizing": {
      background: "Secondary.500",
      opacity: 1,
    },
    "@media (hover: hover)": {
      ".resizer": {
        opacity: 0,
      },
      ":hover > .resizer": {
        opacity: 1,
      },
    },
  },
}

export default GlobalStyles
