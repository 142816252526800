import React from "react"
import { Flex, VStack } from "@chakra-ui/react"

export default function GradientBox({ children }) {
  return (
    <Flex
      height="100vh"
      width="45vw"
      bgGradient="linear(to-b, #3f268f, #860ebf)"
      alignItems="center"
      justifyContent="center"
    >
      <VStack
        minWidth="60%"
        minHeight="80%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {children}
      </VStack>
    </Flex>
  )
}
