import { extendTheme } from "@chakra-ui/react"
import Colors from "./theme/Colors"
import Fonts from "./theme/Fonts"
import FontWeight from "./theme/FontWeights"
import BorderRadii from "./theme/BorderRadii"
import GlobalStyle from "./theme/GlobalStyles"
import Components from "./theme/Components"
import FontSizes from "./theme/FontSizes"

const CustomTheme = extendTheme({
  colors: Colors,
  fonts: Fonts,
  fontWeight: FontWeight,
  fontSize: FontSizes,
  borderRadius: BorderRadii,
  styles: GlobalStyle,
  components: Components,
})

export default CustomTheme
