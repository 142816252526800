import React from "react"
import { Link as ChakraLink } from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"

export default function Link({ children, ...props }) {
  return (
    <ChakraLink as={ReactRouterLink} {...props}>
      {children}
    </ChakraLink>
  )
}
