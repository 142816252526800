import React from "react"
import { Icon } from "@chakra-ui/react"

const IconSave = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="1em" {...props}>
    <path
      fill="currentColor"
      d="M0 6C0 3.17157 0 1.75736 0.87868 0.87868C1.75736 0 3.17157 0 6 0H12.3431C13.1606 0 13.5694 0 13.9369 0.152241C14.3045 0.304482 14.5935 0.593512 15.1716 1.17157L16.8284 2.82843C17.4065 3.40649 17.6955 3.69552 17.8478 4.06306C18 4.4306 18 4.83935 18 5.65685V12C18 14.8284 18 16.2426 17.1213 17.1213C16.48 17.7626 15.5534 17.9359 14 17.9827V15L14 14.9384C14.0001 14.2843 14.0001 13.6965 13.9362 13.2208C13.8663 12.7015 13.7042 12.1687 13.2678 11.7322C12.8313 11.2958 12.2985 11.1337 11.7792 11.0638C11.3034 10.9999 10.7157 10.9999 10.0616 11L10 11H7L6.93839 11C6.28427 10.9999 5.69655 10.9999 5.22084 11.0638C4.70149 11.1337 4.16867 11.2958 3.73223 11.7322C3.29579 12.1687 3.13366 12.7015 3.06383 13.2208C2.99988 13.6965 2.99993 14.2843 3 14.9384L3 15V17.9239C2.02491 17.828 1.36857 17.6112 0.87868 17.1213C0 16.2426 0 14.8284 0 12V6ZM12 15V18H6C5.64496 18 5.31221 18 5 17.9983V15C5 14.2646 5.00212 13.8137 5.046 13.4873C5.08457 13.2005 5.13942 13.1526 5.14592 13.1469L5.14645 13.1464L5.14692 13.1459C5.1526 13.1394 5.20049 13.0846 5.48734 13.046C5.81369 13.0021 6.26462 13 7 13H10C10.7354 13 11.1863 13.0021 11.5127 13.046C11.7995 13.0846 11.8474 13.1394 11.8531 13.1459L11.8536 13.1464L11.8541 13.1469C11.8606 13.1526 11.9154 13.2005 11.954 13.4873C11.9979 13.8137 12 14.2646 12 15ZM4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H9C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H4Z"
    />
  </Icon>
)

export default IconSave
