export function isEmpty(value) {
  return (
    value && value.constructor === Object && Object.keys(value).length === 0
  )
}

export function onChange(fn) {
  return (e) => {
    return fn(e.target.value)
  }
}

// TODO(shakefu): samesies
export function onEnter(fn) {
  return (e) => {
    if (e.key !== "Enter") return
    fn()
  }
}

//Will need to be updated once we have a full release product
export function getEnvironment() {
  const fullUrl = window.location.href
  if (fullUrl.includes("demo")) {
    return "Demo"
  } else if (fullUrl.includes("beta") || fullUrl.includes("local")) {
    return "Beta"
  } else {
    return ""
  }
}
