module.exports = exports = {
  BLINDINSIGHT_PROXY_URL: "https://proxy.local.blindinsight.io",
  BLINDINSIGHT_UI_SENTRY_DSN: "",
  BLINDINSIGHT_STRIPE_PUBLISHABLE_KEY:
    "pk_test_51OyyPU2LBQb5RXBfjukfne8e0AJHcEfZoGRSRB9N64deDU8vLbTB9R0SX5G6n8h740WDynuRkbnu6rFDwnoDzRSH00mtUSyLKT",
  BLINDINSIGHT_STRIPE_PRICING_TABLE_ID: "prctbl_1QII1e2LBQb5RXBfgULwkpoj",
  BLINDINSIGHT_STRIPE_BILLING_URL:
    "https://billing.stripe.com/p/login/test_bIYaHT0Ub6xw21qbII",
  BLINDINSIGHT_GOOGLE_CLIENT_ID:
    "258925018220-f7a0nql23g3fcb94ubq3ihj13o2ov8on.apps.googleusercontent.com",
}
