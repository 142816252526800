import React from "react"
import { Form, useSearchParams } from "react-router-dom"
import { Api } from "../lib/api"
import {
  Alert,
  Image,
  Input,
  Button,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react"
import Logo from "../images/logoverticallight-1.png"
import GradientBox from "./GradientBox"

export default function ResetPassword({ setResetSuccess, setResetError }) {
  const [searchParams] = useSearchParams()
  const [error, setError] = React.useState(null)
  const api = new Api()

  const user_id = searchParams.get("user_id")
  const timestamp = searchParams.get("timestamp")
  const signature = searchParams.get("signature")

  React.useEffect(() => {
    if (!user_id || !timestamp || !signature) {
      setResetError("Invalid password reset link. Please request a new one.")
    }
  }, [user_id, timestamp, signature])

  const handlePasswordReset = (event) => {
    event.preventDefault()

    const password = event.target.password.value
    const password_confirm = event.target.password_confirm.value

    if (password !== password_confirm) {
      console.log("Passwords do not match")
      setError("Passwords do not match")
      return
    }

    const resetPassword = async () => {
      console.log("Sending password reset request")
      const resp = await api.accountsResetPassword(
        user_id,
        timestamp,
        signature,
        password,
      )
      return resp
    }
    const resp = resetPassword()
    if (!resp) {
      console.log("Reset failed")
      setResetError(resp.detail)
      return
    }
    console.log("Reset success")
    resp.then((resp) => {
      setResetSuccess(getSuccessMessage(resp))
    })
  }

  const getSuccessMessage = (resp) => {
    return (
      <GradientBox>
        <Text>
          {resp.detail}. <Link href="/">Click here to login</Link>
        </Text>
      </GradientBox>
    )
  }

  return (
    <GradientBox>
      <Image src={Logo} alt="Blind Insight Logo" />
      {error && <Alert severity="error">{error}</Alert>}
      <VStack
        minWidth="100%"
        justifyContent="space-between"
        alignItems="left"
        mt="8em"
      >
        <Form onSubmit={handlePasswordReset}>
          <VStack
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            minWidth="100%"
            spacing="0"
          >
            <Text variant="subtext">Enter your new password</Text>
            <Input
              variant="account"
              type="password"
              name="password"
              id="password"
              status={error && "error"}
              isRequired
            />
            <Text variant="subtext">{error || " "}</Text>
            <Text variant="subtext" mt="1.5em">
              Re-type your new password
            </Text>
            <Input
              variant="account"
              type="password"
              name="password_confirm"
              id="password_confirm"
              status={error && "error"}
              isRequired
            />
            {error || " "}
            <Button variant="account" mt="2em" type="submit">
              Reset Password
            </Button>
          </VStack>
        </Form>
      </VStack>
    </GradientBox>
  )
}
