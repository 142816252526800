import React from "react"
import { Outlet, useParams, useLocation } from "react-router-dom"
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import Sidebar from "./Sidebar"
import Link from "./Link"
import { Datasets, Schemas } from "../lib/store"

export default function SidebarLayout() {
  const { datasetId, schemaId } = useParams()
  const location = useLocation()
  const [datasets] = Datasets.useState()
  const [schemas] = Schemas.useState()

  // TODO(jathan): Factor the breadcrumb logic out of this component and into a hook.
  const getBreadcrumbs = () => {
    const breadcrumbs = []

    // Datasets
    if (location.pathname.includes("/datasets")) {
      breadcrumbs.push({
        label: "Datasets",
        path: "/datasets",
        current: location.pathname === "/datasets",
      })
    }

    // Dataset
    if (datasetId) {
      const dataset = datasets[datasetId]

      // Dataset detail
      if (dataset) {
        breadcrumbs.push({
          label: dataset.name,
          path: `/datasets/${datasetId}`,
          current: location.pathname === `/datasets/${datasetId}`,
        })

        // Create schema
        if (location.pathname.includes("/schemas/create")) {
          breadcrumbs.push({
            label: "Create Schema",
            path: `/datasets/${datasetId}/schemas/create`,
            current: true,
          })
        }
      }
    }

    // Schema
    if (schemaId) {
      const schema = schemas[schemaId]
      if (schema) {
        // Schema detail
        breadcrumbs.push({
          label: schema.name,
          path: `/datasets/${datasetId}/schemas/${schemaId}`,
          current:
            location.pathname === `/datasets/${datasetId}/schemas/${schemaId}`,
        })

        // Schema records
        if (location.pathname.includes("/records")) {
          breadcrumbs.push({
            label: "Records",
            path: `/datasets/${datasetId}/schemas/${schemaId}/records`,
            current: true,
          })
        }
      }
    }

    function addOrganizationBreadcrumb() {
      breadcrumbs.push({
        label: "Organization",
        path: "#",
        current: false,
      })
    }

    // Account
    if (location.pathname.includes("/account")) {
      addOrganizationBreadcrumb()
      breadcrumbs.push({
        label: "Account",
        path: "/account",
        current: location.pathname === "/account",
      })
    }

    // Profile
    if (location.pathname.includes("/profile")) {
      addOrganizationBreadcrumb()
      breadcrumbs.push({
        label: "Profile",
        path: "/profile",
        current: location.pathname === "/profile",
      })
    }

    // Users
    if (location.pathname.includes("/users")) {
      addOrganizationBreadcrumb()
      breadcrumbs.push({
        label: "Users",
        path: "/users",
        current: location.pathname === "/users",
      })
    }

    return breadcrumbs
  }

  return (
    <Box display="flex" height="100vh" maxW="100vw" overflow="hidden">
      <Sidebar />
      <Box
        padding="1em"
        maxWidth="calc(100vw - 14em)"
        minWidth="calc(100vw - 14em)"
        maxHeight="100vh"
        bg="Gray.100"
      >
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          {getBreadcrumbs().map((crumb) => (
            <BreadcrumbItem key={crumb.path} isCurrentPage={crumb.current}>
              <BreadcrumbLink as={Link} to={crumb.path}>
                {crumb.label}
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        <Outlet />
      </Box>
    </Box>
  )
}
