import React from "react"
import { UserLogout } from "../lib/user"
import { useNavigate } from "react-router-dom"

const LogoutView = () => {
  const navigate = useNavigate()
  React.useEffect(() => {
    UserLogout()
    navigate("/login")
  }, [])

  return <></>
}

export default LogoutView
