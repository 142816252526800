const Accordions = {
  baseStyle: {},
  variants: {
    standard: {
      allowMultiple: true,
      container: {
        border: "none",
        borderStyle: "none",
        color: "white",
        width: "14em",
      },
      button: {
        justifyContent: "center",
        pt: "2em",
        pb: "2em",
        as: "Link",
        bg: "Primary.800",
        color: "white",
        border: "none",
        borderRadius: "none",
        boxShadow: "none",
        width: "100%",
        _hover: {
          borderRadius: "none",
          bg: "Primary.900",
          textDecoration: "underline",
        },
        panel: {
          bg: "Primary.700",
        },
      },
    },
    dataSets: {
      allowMultiple: true,
      font: "body",
      root: {
        margin: "0",
      },
      container: {
        border: "none",
        borderStyle: "none",
        color: "white",
      },
      button: {
        paddingLeft: "1em",
        bg: "Primary.700",
        color: "white",
        border: "none",
        borderRadius: "none",
        boxShadow: "none",
        width: "100%",
        _hover: {
          bg: "Primary.800",
        },
      },
      panel: {
        paddingLeft: "2em",
        bg: "Primary.700",
        pb: "0.25em",
        pt: "0.25em",
      },
    },
  },
}

export default Accordions
