const Tabs = {
  baseStyle: {},
  variants: {
    standard: {
      marginLeft: "0",
      root: {
        justifySelf: "flex-start",
      },
      tabpanel: {
        margin: "0.5em",
        padding: "0",
      },
      tab: {
        height: "2em",
        color: "Gray.700",
        fonts: "heading",
        spacing: "0.5em",
        fontSize: "1.5em",
        borderRadius: "0",
        borderStyle: "none",
        boxShadow: "none",
        fontWeight: "100",
        textDecoration: "none",
        _hover: {
          color: "Gray.800",
          bg: "Gray.200",
          borderRadius: "0.4em 0.4em 0 0",
          textDecoration: "none",
          _disabled: {
            textDecoration: "none",
          },
        },
        _disabled: {
          color: "Gray.300",
          bg: "none",
        },
        _selected: {
          color: "Gray.500",
          borderStyle: "solid",
          borderColor: "Primary.500",
          borderWidth: "0 0 0.1em 0",
        },
      },
    },
  },
}

export default Tabs
