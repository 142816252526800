import React from "react"
import { Outlet } from "react-router-dom"

import { Box, Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react"
import Link from "./Link"

export default function AccountTabs() {
  return (
    <Box>
      <Tabs variant="standard">
        <TabList>
          <Tab>
            <Link to="/account" style={{ textDecoration: "none" }}>
              Account
            </Link>
          </Tab>
          <Tab>
            <Link to="/users" style={{ textDecoration: "none" }}>
              Users
            </Link>
          </Tab>
          <Tab>
            <Link to="/profile" style={{ textDecoration: "none" }}>
              Profile
            </Link>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Outlet />
          </TabPanel>
          <TabPanel>
            <Outlet />
          </TabPanel>
          <TabPanel>
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
