import React, { useState } from "react"
import { Form } from "react-router-dom"
import { useRouteLoaderData } from "react-router-dom"
import { onChange } from "../lib/util"
import {
  Alert,
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from "@chakra-ui/react"
import IconEdit from "@/icons/IconEdit"
import { Api } from "@/lib/api"

export default function ProfileView() {
  const api = new Api()
  const user = useRouteLoaderData("auth")
  let [editMode, setEditMode] = useState("")
  const [error, setError] = React.useState(null)
  const [passwordError, setPasswordError] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangePassword = async (event) => {
    event.preventDefault()
    const { oldPassword, password, passwordConfirm } = event.target.elements

    if (password.value !== passwordConfirm.value) {
      setPasswordError("Passwords do not match")
      return
    }

    let success
    try {
      success = await api.accountsChangePassword(
        oldPassword.value,
        password.value,
        passwordConfirm.value,
      )
    } catch (e) {
      success = false
    }

    if (!success) {
      setError("Failed to update password")
      setSuccess(null)
    } else {
      console.log("Updated password successfully")
      setError(null)
      setSuccess("Password updated successfully.")
    }

    // After updating the password, close the modal and reset the edit mode
    handleClose()
    setEditMode("")
  }

  if (!user) return null
  return (
    <Box display="flex" flexDirection="column" mt="8">
      <Heading fontSize="md">Login & Security</Heading>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Box>
        Email address: {user.email}
        <IconEdit
          ml="1em"
          color="Primary.500"
          as="button"
          onClick={() => {
            handleOpen()
            setEditMode("email")
          }}
        />
      </Box>
      <Box>
        Password: ********
        <IconEdit
          ml="1em"
          as="button"
          color="Primary.500"
          onClick={() => {
            handleOpen()
            setEditMode("password")
          }}
        />
      </Box>
      <Heading fontSize="md">Personal info</Heading>
      <Box>
        Full name: {user.first_name} {user.last_name}
        <IconEdit
          ml="1em"
          as="button"
          color="Primary.500"
          onClick={() => {
            handleOpen()
            setEditMode("name")
          }}
        />
      </Box>
      <Modal isOpen={open} onClose={handleClose} variant="mini">
        <ModalOverlay />
        {(editMode === "name" && (
          <ModalContent>
            <ModalHeader>Update Name</ModalHeader>
            <ModalCloseButton onClick={handleClose} />
            <Form>
              <InputGroup variant="standard">
                <InputLeftAddon>First name: </InputLeftAddon>
                <Input placeholder={user.first_name} isRequired />
              </InputGroup>
              {/* Update Name isn't implemented yet */}
              <ModalFooter>
                <Button
                  float="left"
                  variant="primary"
                  type="submit"
                  onClick={() => {
                    handleClose()
                    setEditMode("")
                  }}
                >
                  Update Name
                </Button>
              </ModalFooter>
            </Form>
          </ModalContent>
        )) ||
          (editMode === "email" && (
            <ModalContent>
              <ModalHeader>Update Email</ModalHeader>
              <ModalCloseButton onClick={handleClose} />
              <Form>
                <InputGroup variant="standardAddon">
                  <InputLeftAddon>Email Address: </InputLeftAddon>
                  <Input
                    name="slug"
                    placeholder={user.email}
                    value={user.email}
                    onChange={onChange(user.email)}
                    isRequired
                  />
                </InputGroup>
                <ModalFooter>
                  <Button
                    float="left"
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      handleClose()
                      setEditMode("")
                    }}
                  >
                    Update Email
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          )) ||
          (editMode === "password" && (
            <ModalContent>
              <ModalHeader>Update Password</ModalHeader>
              {passwordError && <Alert severity="error">{passwordError}</Alert>}
              <ModalCloseButton onClick={handleClose} />
              <Form onSubmit={handleChangePassword}>
                <InputGroup variant="standardAddon">
                  <InputLeftAddon>Old password: </InputLeftAddon>
                  <Input
                    name="oldPassword"
                    placeholder="Enter your old password"
                    size="small"
                    type="password"
                    isRequired
                    label="Old password"
                  />
                </InputGroup>
                <InputGroup mt="0.5em" variant="standardAddon">
                  <InputLeftAddon>New password: </InputLeftAddon>
                  <Input
                    name="password"
                    placeholder="Enter your new password"
                    size="small"
                    type="password"
                    isRequired
                    label="New password"
                  />
                </InputGroup>
                <InputGroup mt="0.5em" variant="standardAddon">
                  <InputLeftAddon>New password (confirm): </InputLeftAddon>
                  <Input
                    name="passwordConfirm"
                    placeholder="Confirm your new password"
                    size="small"
                    type="password"
                    isRequired
                    label="New password (confirm)"
                  />
                </InputGroup>
                <ModalFooter>
                  <Button float="left" variant="primary" type="submit">
                    Update Password
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          ))}
      </Modal>
    </Box>
  )
}
