const Headings = {
  baseStyle: {},
  variants: {
    standard: {
      color: "white",
      fontFamily: "heading",
      fontWeight: 500,
      m: 0,
    },
    standardBlack: {
      color: "black",
      fontFamily: "heading",
      fontWeight: 500,
    },
    standardSub: {
      fontFamily: "heading",
      fontWeight: 400,
      fontSize: "1.75em",
    },
    standardMicro: {
      fontFamily: "heading",
      fontWeight: 400,
      fontSize: "1.25em",
    },
  },
}

export default Headings
