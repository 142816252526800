const Modals = {
  baseStyle: {},
  sizes: {
    standard: {
      height: "90%",
      maxW: "90%",
      overflowY: "auto",
    },
  },
  variants: {
    standard: {
      dialog: {
        height: "85%",
        maxW: "90%",
        overflowY: "auto",
        color: "black",
      },
      closeButton: {
        variant: "grayRounded",
      },
    },
    pricing: {
      dialog: {
        maxW: "60%",
        maxH: "80%",
        overflowY: "auto",
        color: "black",
      },
      closeButton: {
        color: "white",
        bg: "Primary.500",
        _hover: {
          bg: "primaryLight",
        },
      },
    },
    mini: {
      body: {
        pl: "8",
        pr: "8",
        pb: "8",
        pt: "4",
      },
      closeButton: {
        variant: "grayRounded",
      },
      header: {
        pl: "8",
        pr: "8",
        pb: "0",
      },
    },
  },
}

export default Modals
