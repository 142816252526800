import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import Sidebar from "@/components/Sidebar"

export default function ErrorView({ errorObject }) {
  console.log(">>> ErrorView", errorObject)

  return (
    <Box display="flex" height="100vh" maxW="100vw" overflow="hidden">
      <Sidebar />
      <Box
        padding="1em"
        maxWidth="calc(100vw - 14em)"
        minWidth="calc(100vw - 14em)"
        maxHeight="100vh"
        bg="Gray.100"
      >
        <Heading>Something is horribly wrong!</Heading>
        <Text mb="1em">
          If this issue persists, please inspect the console for more details.
        </Text>
        <Box>
          <Heading size="md">Error Details</Heading>
          <pre>{errorObject}</pre>
        </Box>
      </Box>
    </Box>
  )
}
