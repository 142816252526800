const Buttons = {
  baseStyle: {},
  variants: {
    mdlClose: {
      color: "white",
      bg: "BrandAccent",
      _hover: {
        color: "black",
        bg: "BrandAccentLight",
      },
    },
    primary: {
      color: "white",
      borderRadius: "lg",
      bg: "Primary.500",
      p: "2",
      _hover: {
        bg: "Primary.600",
        color: "white",
      },
    },
    secondary: {
      color: "white",
      borderRadius: "lg",
      bg: "Secondary.500",
      _hover: {
        bg: "Secondary.600",
        color: "white",
        _disabled: {
          bg: "Gray.400",
          color: "white",
        },
      },
      _disabled: {
        bg: "Gray.400",
        color: "white",
      },
    },
    grayRounded: {
      color: "white",
      borderRadius: "full",
      bg: "Gray.500",
      _hover: {
        bg: "Gray.600",
        color: "white",
      },
    },
    outline: {
      color: "white",
      border: "1px",
      borderColor: "white",
      bg: "none",
      _hover: {
        bg: "Brand.PrimaryDark",
        color: "white",
      },
    },
    standardSqr: {
      color: "white",
      bg: "Brand.Secondary",
      _hover: {
        bg: "Brand.SecondaryDark",
        color: "white",
        _disabled: {
          bg: "Gray.400",
          color: "Gray.600",
        },
      },
      _disabled: {
        bg: "Gray.400",
        color: "Gray.600",
      },
    },
    link: {
      padding: "0.5em",
      color: "white",
      bg: "Secondary.80",
      borderRadius: "0",
      boxShadow: "none",
      display: "flex",
      justifyContent: "flex-start",
      fontSize: "1.25em",
      fontWeight: "100",
      _hover: {
        bg: "secondary",
      },
    },
    profile: {
      padding: "0.5em",
      color: "white",
      bg: "transparent",
      borderRadius: "0",
      boxShadow: "none",
      display: "flex",
      justifyContent: "flex-start",
      fontWeight: "100",
      _hover: {
        bg: "secondary",
      },
    },
    table: {
      size: "sm",
      color: "Primary.500",
      bg: "Gray.100",
      _hover: {
        bg: "Secondary.30",
      },
    },
    filter: {
      borderRadius: "8em",
      height: "2em",
      width: "fit-content",
      pl: "0.4em",
      pr: "0.4em",
      fontSize: "0.85em",
      color: "white",
      bg: "Primary.500",
      _hover: {
        bg: "primaryDark",
        color: "white",
      },
    },
    clearFilter: {
      borderRadius: "8em",
      height: "2em",
      width: "fit-content",
      pl: "0.4em",
      pr: "0.4em",
      fontSize: "0.85em",
      color: "Secondary.500",
      _hover: {
        color: "Secondary.600",
      },
    },
    account: {
      color: "Accent.700",
      bg: "Gray.100",
      borderRadius: "1em",
      minWidth: "100%",
      height: "3em",
      _hover: {
        bg: "Gray.200",
      },
    },
  },
}

export default Buttons
