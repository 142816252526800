import React from "react"
import AccountLayout from "@/components/AccountLayout"
import AccountsActivate from "../components/AccountsActivate"
import Message from "@/components/Message"
import { Alert, Heading, Text, Box } from "@chakra-ui/react"

export default function AccountsActivateView() {
  const [activated, setActivated] = React.useState(false)
  const [error, setError] = React.useState(null)

  return (
    <AccountLayout>
      <AccountsActivate setActivated={setActivated} setError={setError} />
      <Message>
        <Heading variant="standardSub">Welcome to Blind Insight!</Heading>
        <Text fontSize="1.5em">Account activation</Text>
        <Text>
          <strong>If you were responding to an Organization invitation:</strong>
        </Text>
        <Text>
          Please check your email for a link to set your initial password.
        </Text>
        <br />
        <Text>
          <strong>If you self-registered:</strong>
        </Text>
        <Text>
          You chose a password during registration and are good to go!
        </Text>
        <Box mt="1em">
          {!activated && <Text>Activating your account...</Text>}
          {activated && <Alert severity="success">{activated}</Alert>}
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
      </Message>
    </AccountLayout>
  )
}
