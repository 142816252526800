import React from "react"
import {
  Outlet,
  useLoaderData,
  useRouteError,
  isRouteErrorResponse,
  redirect,
} from "react-router-dom"
import { CurrentUser } from "../lib/store"
import { UserIsLoggedIn } from "../lib/user"
import LoginView from "../views/LoginView"
import ErrorView from "../views/ErrorView"

export default function AuthRequired() {
  const auth = useLoaderData()
  return <Outlet value={auth} />
}

AuthRequired.loader = async () => {
  let loggedIn

  try {
    loggedIn = await UserIsLoggedIn()
  } catch (error) {
    console.log(">>> Error in AuthRequired.loader")
    const badStatuses = [401, 403]
    if (badStatuses.includes(error.status)) {
      console.log(">>> Redirecting to login")
      return redirect("/login")
    }
    throw error
  }

  if (!loggedIn) {
    console.log("No auth")
    throw new Response(JSON.stringify({ error: "No auth" }), { status: 403 })
  }

  if (loggedIn) {
    console.log("Is logged in")
    return CurrentUser.getValue()
  }

  console.log("Bad auth state")
  throw new Response(JSON.stringify({ error: "Bad auth state" }), {
    status: 403,
  })
}

export function AuthRequiredError() {
  console.log(">>> AuthRequiredError called")
  const error = useRouteError()

  // If the error is not a route error, then it's an unhandled error.
  if (!isRouteErrorResponse(error)) {
    return <ErrorView errorObject={error.stack} />
  }

  // If the error is a route error, then it's a handled error.
  switch (error.status) {
    case 400:
      // No password given
      return <LoginView />
    case 422:
      // TODO(jathan): Display an actual proxy error screen.
      // Proxy error. Most likely failed to pass through auth.
      return <LoginView />
    default:
      return <ErrorView errorObject={JSON.stringify(error, null, 2)} />
  }
}
