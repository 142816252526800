const Colors = {
  Primary: {
    50: "#E3E0EF",
    100: "#BAAEDF",
    200: "#9786D0",
    300: "#7059BD",
    400: "#6349B8",
    500: "#4C2EAC",
    600: "#4021A3",
    700: "#3E2688",
    800: "#39257B",
    900: "#31206A",
  },
  Secondary: {
    50: "#E6F1F9",
    100: "#C1DDF1",
    200: "#5EA6D9",
    300: "529FD6",
    400: "#2D8BCE",
    500: "#0876C5",
    600: "#0764A7",
    700: "#06538A",
    800: "#054776",
    900: "#043B63",
  },
  Accent: {
    50: "#FBE5F4",
    100: "#F1A6D8",
    200: "#E559B6",
    300: "#E34DB1",
    400: "#DF33A5",
    500: "#D6008F",
    600: "#C10081",
    700: "#AB0072",
    800: "#8B005D",
    900: "#6B0048",
  },
  Gray: {
    50: "#FAFAFA",
    100: "#F3F3F3",
    200: "#E6E6E6",
    300: "#CECECE",
    400: "#B5B5B5",
    500: "#67657B",
    600: "#535166",
    700: "#393F4A",
    800: "#31313E",
    900: "#09090A",
  },
}

export default Colors
