import React from "react"
import Logo from "../images/logoverticallight-1.png"

import GradientBox from "./GradientBox"
import { Image } from "@chakra-ui/react"

export default function RegisterConfirm() {
  return (
    <GradientBox>
      <Image src={Logo} alt="Blind Insight Logo" />
    </GradientBox>
  )
}
