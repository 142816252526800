import React from "react"
import {
  Form,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom"
import { Api } from "../lib/api"
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Table,
  Tbody,
  Td,
  TableContainer,
  Thead,
  Th,
  Tr,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
} from "@chakra-ui/react"
import { CurrentOrg } from "../lib/store"
import { AddIcon } from "@chakra-ui/icons"
import IconEdit from "@/icons/IconEdit"

export default function Users() {
  const [organization] = CurrentOrg.useState()
  const currentUser = useRouteLoaderData("auth")
  const [userEditOpen, setUserEditOpen] = React.useState(false)
  const [userCreateOpen, setUserCreateOpen] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState({}) //state for table
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const api = new Api()

  const handleUserEditOpen = () => {
    setUserEditOpen(true)
  }

  const handleUserEditClose = () => {
    setUserEditOpen(false)
  }

  const handleUserCreateOpen = () => {
    setUserCreateOpen(true)
  }

  const handleUserCreateClose = () => {
    setUserCreateOpen(false)
  }

  const handleUserCreateSubmit = async (event) => {
    event.preventDefault()
    const { email, isAdmin } = event.target.elements
    let success
    try {
      success = await api.organizationsInvitationsCreate(
        organization.id,
        email.value,
        isAdmin.checked,
      )
    } catch (e) {
      success = false
    }
    if (!success) {
      console.log("Failed to create user")
      setError("Failed to create user")
      setSuccess(null)
    } else {
      console.log("User created successfully")
      setError(null)
      setUserCreateOpen(false)
      setSuccess("User created successfully")
      navigate(location)
    }
  }

  const handleUserEditSubmit = async (event) => {
    event.preventDefault()
    const { userId, isAdmin } = event.target.elements
    let success
    try {
      success = await api.organizationsUsersPartialUpdate(
        organization.id,
        userId.value,
        isAdmin.checked,
      )
    } catch (e) {
      success = false
    }
    if (!success) {
      console.log("Failed to update user")
      setSuccess(null)
      setError("Failed to update user")
    } else {
      console.log("User updated successfully")
      setError(null)
      setUserEditOpen(false)
      setSuccess("User updated successfully")
      navigate(location)
    }
  }

  const orgOwner = React.useMemo(() => {
    return organization.owner.organization_user
  }, [organization])

  const currentOrgUser = React.useMemo(() => {
    return organization.organization_users.find(
      (ou) => ou.user.email === currentUser.email,
    )
  }, [organization, currentUser])

  const orgUsers = React.useMemo(() => {
    return organization.organization_users
  }, [organization])

  const getUserRole = (orgUser) => {
    if (!orgUser) return

    if (orgUser.user.email === orgOwner.user.email) {
      return "Owner"
    } else if (orgUser.is_admin) {
      return "Data Owner"
    }
    return "Data Requester"
  }

  const shouldDisableIsAdmin = (orgUser) => {
    if (!orgUser || !orgUser?.user) return

    if (orgUser.user.email === currentUser.email) {
      return true
    } else if (orgUser.user.email === orgOwner.user.email) {
      return true
    } else if (!currentOrgUser?.is_admin) {
      return true
    }

    return false
  }

  return (
    <>
      <Box>
        <Text>Organization Owner: {orgOwner.user.email}</Text>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="primary"
            size="sm"
            onClick={handleUserCreateOpen}
            rightIcon={<AddIcon />}
          >
            Add User
          </Button>
        </Box>
        <TableContainer
          mt="1em"
          borderRadius="0.5em"
          boxShadow="3px 3px 10px gray"
          maxHeight={`calc(100vh - 11.5em)`}
          overflowY="auto"
        >
          {error && (
            <Alert
              severity="error"
              onClose={() => {
                setError(null)
              }}
            >
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              onClose={() => {
                setSuccess(null)
              }}
            >
              {success}
            </Alert>
          )}
          <Table variant="standard">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Status</Th>
                <Th>Role</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orgUsers.map((orgUser) => (
                <Tr key={orgUser.id} onClick={() => setSelectedUser(orgUser)}>
                  <Td>{orgUser.user.email}</Td>
                  <Td>{orgUser.user.is_active ? "Active" : "Invited"}</Td>
                  <Td>{getUserRole(orgUser)}</Td>
                  <Td>
                    <a href="#" onClick={handleUserEditOpen}>
                      <IconEdit color="Primary.500" />
                    </a>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Modal variant="mini" isOpen={userEditOpen} onClose={handleUserEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form onSubmit={handleUserEditSubmit}>
              <Box display="flex" flexDirection="column" padding="1em" gap="2">
                <strong>Email:</strong>
                <Input
                  id="changeEmail"
                  name="userEmail"
                  type="email"
                  value={selectedUser?.user?.email}
                />

                <Input
                  id={selectedUser?.id}
                  name="userId"
                  type="hidden"
                  value={selectedUser?.id}
                />
                <Checkbox
                  variant="standard"
                  id="isAdmin"
                  name="isAdmin"
                  label="Role"
                  disabled={shouldDisableIsAdmin(selectedUser)}
                  defaultChecked={selectedUser?.is_admin}
                >
                  <Text color="black">Data Owner</Text>
                </Checkbox>
              </Box>
              <Box display="flex" justifyContent="flex-end" gap="2">
                <Button
                  as="Link"
                  buttonStyle="inline"
                  title="Close dialogue"
                  size="sm"
                  onClick={handleUserEditClose}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  disabled={shouldDisableIsAdmin(selectedUser)}
                  type="submit"
                >
                  Update
                </Button>
              </Box>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        variant="mini"
        isOpen={userCreateOpen}
        onClose={handleUserCreateClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form onSubmit={handleUserCreateSubmit}>
              <Box display="flex" flexDirection="column" padding="1em" gap="2">
                <Text color="black">
                  Invite a user to join your organization. If they don{"'"}t
                  have an account, they will be invited to create one.
                </Text>

                <Input
                  variant="primary"
                  name="email"
                  type="email"
                  placeholder="Email"
                  borderColor="black"
                  isRequired
                />
                <Checkbox
                  id="isAdmin"
                  name="isAdmin"
                  label="Data Owner"
                  variant="standard"
                >
                  <Text color="black">Data Owner</Text>
                </Checkbox>
                <Box display="flex" justifyContent="flex-end" gap="2">
                  <Button
                    as="Link"
                    buttonStyle="inline"
                    title="Close dialogue"
                    size="sm"
                    onClick={handleUserCreateClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    text="Add User"
                    type="submit"
                    size="sm"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
