const Checkboxes = {
  baseStyle: {
    label: {
      color: "white",
      fontFamily: "body",
    },
  },
  variants: {
    standard: {
      control: {
        borderColor: "Primary.500",
        bg: "white",
        _checked: {
          bg: "Primary.500",
          borderColor: "Primary.500",
          _hover: {
            bg: "primaryLight",
            borderColor: "Primary.500",
          },
        },
        _hover: {
          bg: "primaryLight",
        },
        _disabled: {
          color: "Gray.900",
          borderColor: "Gray.300",
        },
      },
    },
    standardModal: {
      control: {
        borderColor: "Primary.500",
        bg: "white",
        _checked: {
          bg: "Primary.500",
          borderColor: "Primary.500",
          _hover: {
            bg: "primaryLight",
            borderColor: "Primary.500",
          },
        },
        _hover: {
          bg: "primaryLight",
        },
      },
    },
  },
}

export default Checkboxes
