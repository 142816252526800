import React from "react"
import Account from "../components/Account"
import { Box } from "@chakra-ui/react"

export default function AccountView() {
  return (
    <Box>
      <Account />
    </Box>
  )
}
