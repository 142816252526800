/*
This code was adapted from https://github.com/adaydesign/react-chakra-ui-table-v2

It was converted from TypeScript to JavaScript.
*/

export const getCurrency = (n, f = 2) => {
  if (!n) return 0

  const options = {
    style: "decimal", // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: f,
    maximumFractionDigits: f,
  }
  return n.toLocaleString("en-US", options)
}

export const getNumformat = (n) => {
  if (!n) return 0

  const options = {
    style: "decimal", // Other options: 'currency', 'percent', etc.
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
  return n.toLocaleString("en-US", options)
}
