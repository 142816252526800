const Selects = {
  default: {},
  variants: {
    filter: {
      field: {
        height: "2em",
        fontSize: "0.85em",
        bg: "white",
        color: "black",
        fontWeight: 500,
        padding: "0.25em",
        borderRadius: "8em",
      },
    },
    filterOperand: {
      field: {
        fontSize: "0.75em",
        height: "1.8em",
        bg: "secondaryLight",
        color: "black",
        fontWeight: 500,
        padding: "0.25em",
        borderRadius: "0.25em",
        mt: "0.1em",
        mb: "0.1em",
      },
      icon: {
        iconSize: "1em",
      },
    },
  },
}

export default Selects
