const Tables = {
  baseStyle: {},
  variants: {
    standard: {
      table: {
        mt: "0em",
        bg: "white",
        borderRadius: "0.5em",
      },
      thead: {
        position: "sticky",
        top: "0",
        borderBottom: "1px solid",
        borderColor: "Gray.300",
        borderRadius: "0.5em",
        bg: "Gray.50",
      },
    },
    dataTable: {
      table: {
        mt: "0em",
        bg: "white",
        borderRadius: "0.5em",
        tableLayout: "fixed",
        width: "100%",
        overflowY: "auto",
      },
      thead: {
        position: "sticky",
        top: "0",
        borderBottom: "1px solid",
        borderColor: "Gray.300",
        borderRadius: "0.5em",
        bg: "Gray.50",
      },
      td: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "0.8em",
        padding: "1em",
      },
      th: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "0.8em",
        padding: "1em",
      },
    },
  },
}

export default Tables
