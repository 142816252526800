import React, { useState } from "react"
import { useRouteLoaderData, Form } from "react-router-dom"
import { CurrentOrg } from "../lib/store"
import {
  Box,
  Button,
  Input,
  Heading,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react"
import { MdManageAccounts } from "react-icons/md"
import IconEdit from "@/icons/IconEdit"
import IconSave from "@/icons/IconSave"
import env from "../lib/env"

const stripeBillingUrl =
  process.env.BLINDINSIGHT_STRIPE_BILLING_URL ||
  env.BLINDINSIGHT_STRIPE_BILLING_URL

export default function Account() {
  let [editMode, setEditMode] = useState("")
  const [currentOrg, setCurrentOrg] = CurrentOrg.useState()
  const user = useRouteLoaderData("auth")

  return (
    <Box mt="8">
      <Heading fontSize="sm">Organization name</Heading>
      {(editMode === "orgname" && (
        <>
          <Form className="columns">
            <InputGroup variant="standardAddon">
              <InputLeftAddon>Organization name: </InputLeftAddon>
              <Input
                name="orgName"
                placeholder={currentOrg.name}
                value={currentOrg.name}
                onChange={setCurrentOrg.name}
                isRequired
              />
              <InputRightAddon>
                <IconSave
                  as="button"
                  type="submit"
                  color="Primary.500"
                  onClick={() => {
                    setEditMode("")
                  }}
                />
              </InputRightAddon>
            </InputGroup>
          </Form>
        </>
      )) || (
        <Box>
          {currentOrg.name}{" "}
          <IconEdit
            as="Button"
            color="Primary.500"
            onClick={() => {
              setEditMode("orgName")
            }}
          />
        </Box>
      )}
      <Heading fontSize="sm">Subdomain (slug)</Heading>
      {(editMode === "slug" && (
        <>
          <Form>
            <InputGroup variant="standardAddon">
              <InputLeftAddon>Subdomain: </InputLeftAddon>
              <Input
                placeholder={currentOrg.slug}
                value={currentOrg.slug}
                onChange={setCurrentOrg.slug}
                isRequired
              />
              <InputRightAddon>
                <Text>.blindinsight.io</Text>
                <IconSave
                  as="button"
                  type="submit"
                  color="Primary.500"
                  onClick={() => {
                    setEditMode("")
                  }}
                />
              </InputRightAddon>
            </InputGroup>
          </Form>
        </>
      )) || (
        <Text color="black">
          {currentOrg.slug}.blindinsight.io{" "}
          <IconEdit
            as="Button"
            color="Primary.500"
            onClick={() => {
              setEditMode("slug")
            }}
          />
        </Text>
      )}
      <Heading fontSize="sm">Subscription Type</Heading>
      <Text color="black">
        <b>{user.settings?.stripe?.product_name || "Free"}</b> (Beta){" "}
        <Button
          variant="primary"
          size="xs"
          rightIcon={<MdManageAccounts />}
          onClick={() => {
            window.open(
              `${stripeBillingUrl}?prefilled_email=${user.email}`,
              "_blank",
            )
          }}
        >
          Manage
        </Button>
      </Text>
    </Box>
  )
}
