import React from "react"
import { Icon } from "@chakra-ui/react"

const IconEdit = (props) => (
  <Icon viewBox="0 0 24 24" boxSize="1em" {...props}>
    <path
      fill="currentColor"
      d="M-1 18a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H0a1 1 0 0 1-1-1Z"
    />
    <path
      fill="currentColor"
      d="M3.824 16 15.566 4.911 13.859 3.3 2 14.5V16h1.824ZM0 13.637 13.042 1.32a1.204 1.204 0 0 1 1.633 0l2.987 2.82a1.048 1.048 0 0 1 0 1.543L4.62 18H0v-4.363Z"
    />
  </Icon>
)

export default IconEdit
