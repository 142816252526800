const Alerts = {
  baseStyle: {},
  variants: {
    standard: {
      container: {
        mt: "0.5em",
        mb: "0.5em",
        padding: "0.5em",
        borderRadius: "0.5em",
        bg: "Secondary.100",
        color: "black",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
}

export default Alerts
