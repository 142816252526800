/*
This code was adapted from https://github.com/adaydesign/react-chakra-ui-table-v2

It was converted from TypeScript to JavaScript and enhanced to support pagination, sorting, and filtering.
*/

import React from "react"
import {
  Table as CKTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Heading,
  HStack,
  Button,
  Select,
  Text,
  Input,
  Flex,
  TableContainer,
  Icon,
  Checkbox,
  MenuButton,
  MenuList,
  VStack,
  useDisclosure,
  Box,
  Spinner,
  CheckboxGroup,
  Menu,
} from "@chakra-ui/react"

import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Search2Icon,
  SearchIcon,
  TriangleDownIcon,
  TriangleUpIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons"
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getFilteredRowModel,
} from "@tanstack/react-table"
import { FaTrash, FaDownload } from "react-icons/fa6"
import "jspdf-autotable"
import { useMemo, useState } from "react"
import { SingleDatepicker } from "chakra-dayzed-datepicker"
import { getNumformat } from "@/lib/formatters"

export const DEFAULT_PAGES = [25, 50, 100]

export const NoDataDisplay = () => {
  return (
    <Flex
      direction="column"
      p="10"
      pt="2"
      gap="3"
      borderRadius="8"
      alignContent="left"
      bgColor="Gray.100"
      m="auto"
    >
      <Heading size="md">Looks like you need to add some data!</Heading>
      <Text fontSize="md" sx={{ "text-wrap": "auto" }}>
        Only encrypted data ever enters or exits Blind Insight, and we
        don&apos;t hold any keys. Download the Blind Proxy to encrypt data in
        your own environment before sending to Blind Insight. Soon, the Blind
        Proxy will be available as a browser extension, mobile library, and via
        secure enclave.
      </Text>
      <Flex width="full" direction="row" justifyContent="right" gap="4">
        <a
          href="https://docs.blindinsight.io/download/"
          target="_blank"
          rel="noreferrer"
        >
          Download a sample dataset
        </a>
        <Button
          variant="primary"
          title="Blind Proxy Download Page"
          size="md"
          rightIcon={<FaDownload />}
          href="https://docs.blindinsight.io/download/"
        >
          <a
            href="https://docs.blindinsight.io/download/"
            target="_blank"
            rel="noreferrer"
          >
            Download the Blind Proxy
          </a>
        </Button>
      </Flex>
    </Flex>
  )
}

export const LoadingDataDisplay = () => {
  return (
    <Flex
      direction="column"
      p={4}
      align="center"
      justify="center"
      bgColor="Gray.100"
    >
      <Spinner
        size="xl"
        boxSize="70px"
        thickness="0.25rem"
        mb={3}
        color="Gray.400"
      />
      <Text>Loading Data</Text>
    </Flex>
  )
}

export const ErrorDisplay = ({ message }) => {
  const DEFAULT_ERROR_MESSAGE = "Error"

  return (
    <Flex
      direction="column"
      p={4}
      align="center"
      justify="center"
      bgColor="Gray.100"
    >
      <Icon as={WarningTwoIcon} boxSize="70px" mb={3} color="Gray.400" />
      <Text>{message ?? DEFAULT_ERROR_MESSAGE}</Text>
    </Flex>
  )
}

export function DataTable({
  EncryptButton,
  data = [],
  columns,
  isLoading = false,
  error = undefined,
  initialSortingState = [],
  initialPaginationState = { pageSize: DEFAULT_PAGES[0] },
  initialColumnVisibility = {},
  initialColumnFilters = [],
  filterIsOpen = false,
  rowCount = -1,
  pagination = { pageIndex: 0, pageSize: DEFAULT_PAGES[0] },
  onPaginationChange = () => {},
}) {
  const [sorting, setSorting] = useState(initialSortingState)
  const [columnVisibility, setColumnVisibility] = useState(
    initialColumnVisibility,
  )
  const [columnFilters, setColumnFilters] = useState(initialColumnFilters)
  const filterDisclosure = useDisclosure({
    defaultIsOpen: filterIsOpen,
  })

  const table = useReactTable({
    columns,
    data: data || [],
    initialState: { pagination: initialPaginationState },
    autoResetPageIndex: false,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    defaultColumn: {
      width: "auto",
    },
    state: {
      pagination: pagination,
      sorting,
      columnVisibility,
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    // sorting
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // pagination
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: onPaginationChange,
    manualPagination: true,
    rowCount: rowCount,
    // column visible
    onColumnVisibilityChange: setColumnVisibility,
    // column filter
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const countMaxColumns = useMemo(() => {
    return Math.max(
      ...table.getHeaderGroups().map((headerGroup) => {
        return headerGroup.headers.length
      }),
    )
  }, [table])

  return (
    <VStack spacing="0" maxHeight={`calc(100vh - 11.5em)`}>
      {/* table controls  */}
      <HStack
        align="center"
        justify="flex-start"
        bg="white"
        borderRadius="0.5em 0.5em 0 0"
        padding="3"
        w="full"
      >
        <Button
          size="sm"
          variant="table"
          onClick={() => table.setPageIndex(0)}
          isDisabled={!table.getCanPreviousPage()}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          size="sm"
          variant="table"
          onClick={() => table.previousPage()}
          isDisabled={!table.getCanPreviousPage()}
        >
          <ChevronLeftIcon />
        </Button>
        <Text minW="fit-content">
          {`Page ${
            table.getState().pagination.pageIndex + 1
          } / ${table.getPageCount()}`}
        </Text>
        <Button
          size="sm"
          variant="table"
          onClick={() => table.nextPage()}
          isDisabled={!table.getCanNextPage()}
        >
          <ChevronRightIcon />
        </Button>
        <Button
          size="sm"
          variant="table"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          isDisabled={!table.getCanNextPage()}
        >
          <ArrowForwardIcon />
        </Button>
        <Text minW="fit-content">Go To : </Text>
        <Input
          minW="3em"
          maxW="5em"
          type="number"
          variant="standardAddon"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            table.setPageIndex(page)
          }}
          size="sm"
        />
        <Flex justify="end">
          <Select
            minW="fit-content"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {DEFAULT_PAGES.map((pageSize, index) => (
              <option key={`page-${index}`} value={pageSize}>
                Show {pageSize} rows
              </option>
            ))}
          </Select>
        </Flex>
        <Text color="Gray.500">
          Showing{" "}
          {table.getState().pagination.pageIndex === 0
            ? table.getState().pagination.pageIndex + 1
            : table.getState().pagination.pageIndex *
                table.getState().pagination.pageSize +
              1}
          -
          {(table.getState().pagination.pageIndex + 1) *
            table.getState().pagination.pageSize <
          table.getRowCount()
            ? (table.getState().pagination.pageIndex + 1) *
              table.getState().pagination.pageSize
            : table.getRowCount()}{" "}
          of {getNumformat(table.getRowCount())}
        </Text>
        <Box ml="auto">
          <EncryptButton />
        </Box>
      </HStack>

      {/* table header row */}

      <TableContainer overflowY="auto">
        <CKTable variant="dataTable">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const meta = header.column.columnDef
                    return (
                      <Td
                        key={header.id}
                        isNumeric={meta?.isNumeric}
                        colSpan={header.colSpan}
                        p="2"
                        style={{
                          position: "relative",
                          width: `${header.getSize()}px`,
                        }}
                      >
                        <HStack
                          onClick={header.column.getToggleSortingHandler()}
                          cursor="pointer"
                          gap="0.5rem"
                          w="full"
                          h="2rem"
                        >
                          <Text overflow="hidden" textOverflow="ellipsis">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </Text>
                          {header.column.getCanResize() && (
                            <Box
                              onDoubleClick={() => header.column.resetSize()}
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={`resizer ${
                                header.column.getIsResizing()
                                  ? "isResizing"
                                  : ""
                              }`}
                            />
                          )}
                          <Box w="1rem" display="flex" justifyContent="center">
                            {header.column.getIsSorted() &&
                              (header.column.getIsSorted() === "desc" ? (
                                <TriangleDownIcon aria-label="sorted descending" />
                              ) : (
                                <TriangleUpIcon aria-label="sorted ascending" />
                              ))}
                          </Box>
                        </HStack>

                        <Box>
                          {filterDisclosure.isOpen && (
                            <Menu closeOnSelect={false}>
                              <MenuButton
                                icon={
                                  header.column.getFilterValue() ===
                                  undefined ? (
                                    <SearchIcon />
                                  ) : (
                                    <Search2Icon />
                                  )
                                }
                                isRound={true}
                                variant="ghost"
                                colorScheme={
                                  header.column.getFilterValue() === undefined
                                    ? "gray"
                                    : "orange"
                                }
                                fontSize="1rem"
                                aria-label="column filter"
                                size="sm"
                              />
                              <MenuList p="1">
                                <Flex w="full" direction="column" gap="1">
                                  {header.column.getCanFilter() && (
                                    <Flex>
                                      <Filter
                                        column={header.column}
                                        table={table}
                                      />
                                    </Flex>
                                  )}
                                  <Button
                                    rightIcon={<FaTrash />}
                                    colorScheme="blue"
                                    variant="ghost"
                                    size="sm"
                                    onClick={() =>
                                      header.column.setFilterValue(undefined)
                                    }
                                  >
                                    Reset
                                  </Button>
                                </Flex>
                              </MenuList>
                            </Menu>
                          )}
                        </Box>
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Thead>
          {isLoading ? (
            <Tbody overflowY="scroll">
              <Tr>
                <Td colSpan={countMaxColumns}>
                  <LoadingDataDisplay />
                </Td>
              </Tr>
            </Tbody>
          ) : error ? (
            <Tbody overflowY="scroll">
              <Tr>
                <Td colSpan={countMaxColumns}>
                  <ErrorDisplay message={error.message ?? undefined} />
                </Td>
              </Tr>
            </Tbody>
          ) : data == null || data == undefined || data?.length == 0 ? (
            <Tbody overflowY="scroll">
              <Tr>
                <Td colSpan={countMaxColumns}>
                  <NoDataDisplay />
                </Td>
              </Tr>
            </Tbody>
          ) : (
            data &&
            data?.length > 0 && (
              <Tbody>
                {table.getRowModel().rows?.map((row) => (
                  <Tr
                    key={row.id}
                    _hover={{ shadow: "md", bg: "blackAlpha.50" }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          style={{ width: cell.column.getSize() }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                ))}
              </Tbody>
            )
          )}
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    whiteSpace="normal"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </CKTable>
      </TableContainer>
    </VStack>
  )
}

// Filter Component
const Filter = ({ column, table }) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const columnType = getType(firstValue)
  const facets = column?.getFacetedUniqueValues()
  // Custom facets if keys is array then sum the equal value and remove duplicate
  const customFacets = new Map()
  for (const [key, value] of facets) {
    if (Array.isArray(key)) {
      for (const k of key) {
        const prevValue = customFacets.get(k) || 0
        customFacets.set(k, prevValue + value)
      }
    } else {
      const prevValue = customFacets.get(key) || 0
      customFacets.set(key, prevValue + value)
    }
  }

  const sortedUniqueValues = useMemo(
    () =>
      columnType === "number" || columnType === "date"
        ? []
        : Array.from(customFacets.keys()).sort(),
    [customFacets],
  )

  const meta = column.columnDef.meta
  const switchEval =
    meta && meta.columnType
      ? meta.columnType.toLowerCase()
      : getType(firstValue)

  switch (switchEval) {
    case "number":
      return (
        <HStack w="full" spacing={1}>
          <Input
            type="number"
            size="sm"
            min={Number(
              column.getFacetedMinMaxValues()?.[0] !== undefined &&
                column.getFacetedMinMaxValues()?.[0] !== null
                ? column.getFacetedMinMaxValues()?.[0]
                : "",
            )}
            max={Number(
              column.getFacetedMinMaxValues()?.[1] !== undefined &&
                column.getFacetedMinMaxValues()?.[1] !== null
                ? column.getFacetedMinMaxValues()?.[1]
                : "",
            )}
            value={columnFilterValue?.[0] ?? ""}
            onChange={(e) =>
              column.setFilterValue((old) => [e.target.value, old?.[1]])
            }
            placeholder={`min ${
              column.getFacetedMinMaxValues()?.[0] !== undefined &&
              column.getFacetedMinMaxValues()?.[0] !== null
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ""
            }`}
          />
          <Input
            type="number"
            size="sm"
            min={Number(
              column.getFacetedMinMaxValues()?.[0] !== undefined &&
                column.getFacetedMinMaxValues()?.[0] !== null
                ? column.getFacetedMinMaxValues()?.[0]
                : "",
            )}
            max={Number(
              column.getFacetedMinMaxValues()?.[1] !== undefined &&
                column.getFacetedMinMaxValues()?.[1] !== null
                ? column.getFacetedMinMaxValues()?.[1]
                : "",
            )}
            value={columnFilterValue?.[1] ?? ""}
            onChange={(e) => {
              column.setFilterValue((old) => [old?.[0], e.target.value])
            }}
            placeholder={`max ${
              column.getFacetedMinMaxValues()?.[1] !== undefined &&
              column.getFacetedMinMaxValues()?.[1] !== null
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ""
            }`}
          />
        </HStack>
      )

    case "date":
      return (
        <HStack w="full" spacing={1}>
          <SingleDatepicker
            name="start-date-input"
            date={columnFilterValue?.[0] ?? undefined}
            onDateChange={(newStartDate) =>
              column.setFilterValue((old) => [newStartDate, old?.[1]])
            }
            propsConfigs={{
              inputProps: {
                size: "sm",
                placeholder: "from...",
              },
            }}
            configs={{
              dateFormat: "yyyy-MM-dd",
            }}
          />

          <SingleDatepicker
            name="end-date-input"
            date={columnFilterValue?.[1] ?? undefined}
            onDateChange={(newEndDate) =>
              column.setFilterValue((old) => [old?.[0], newEndDate])
            }
            propsConfigs={{
              inputProps: {
                size: "sm",
                placeholder: "to...",
              },
            }}
            configs={{
              dateFormat: "yyyy-MM-dd",
            }}
          />
        </HStack>
      )

    case "boolean":
      return (
        <HStack w="full" spacing={1}>
          <Select
            id={column.id + "list"}
            placeholder={`select... (${customFacets.size})`}
            size="sm"
            onChange={(e) => {
              column.setFilterValue(
                !e.target.value ? undefined : e.target.value === "true",
              )
            }}
            value={column.getFilterValue() ?? ""}
          >
            {sortedUniqueValues.slice(0, 5000).map((value) => (
              <option value={value} key={value}>
                {value ? "True" : "False"}
              </option>
            ))}
          </Select>
        </HStack>
      )

    case "enum":
      return (
        <Flex w="full">
          <Select
            id={column.id + "list"}
            placeholder={`select... (${customFacets.size})`}
            size="sm"
            onChange={(e) => column.setFilterValue(e.target.value)}
            value={column.getFilterValue() || ""}
          >
            {sortedUniqueValues.slice(0, 5000).map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Select>
        </Flex>
      )

    case "multienum":
      return (
        <Flex w="full">
          <CheckboxGroup
            defaultValue={sortedUniqueValues.slice(0, 5000)}
            onChange={(e) => column.setFilterValue(e)}
            value={column.getFilterValue() || sortedUniqueValues.slice(0, 5000)}
          >
            <VStack align="start">
              {sortedUniqueValues.slice(0, 5000).map((value) => (
                <Checkbox value={value} key={value}>
                  {value}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Flex>
      )

    case "array":
      return (
        <Flex w="full">
          <CheckboxGroup
            defaultValue={sortedUniqueValues.slice(0, 5000)}
            onChange={(e) => column.setFilterValue(e)}
            value={column.getFilterValue() || sortedUniqueValues.slice(0, 5000)}
          >
            <VStack align="start">
              {sortedUniqueValues.slice(0, 5000).map((value) => (
                <Checkbox value={value} key={value}>
                  {value}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Flex>
      )

    case "string":
      return (
        <Flex w="full">
          <Input
            type="text"
            size="sm"
            value={columnFilterValue ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder={`find...`}
            list={column.id + "list"}
          />
        </Flex>
      )

    default:
      return (
        <Flex w="full">
          <Input
            type="text"
            size="sm"
            value={columnFilterValue ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder={`find...`}
            list={column.id + "list"}
          />
        </Flex>
      )
  }
}

function getType(variable) {
  if (variable === null) {
    return "null"
  }

  switch (typeof variable) {
    case "string":
      return "string"
    case "number":
      return "number"
    case "boolean":
      return "boolean"
    case "undefined":
      return "undefined"
    case "function":
      return "function"
    case "object":
      if (variable instanceof Date) {
        return "date"
      }
      if (Array.isArray(variable)) {
        return "array"
      }
      return "object"
    default:
      return "unknown"
  }
}

// summary column
export const getSummary = (table, field) => {
  const sum = table
    .getFilteredRowModel()
    .rows.reduce((total, row) => total + row.getValue(field), 0)
  return sum || 0
}
