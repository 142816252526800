import React from "react"
import {
  Outlet,
  useLoaderData,
  useRouteLoaderData,
  redirect,
} from "react-router-dom"

import { Datasets, Schemas, CurrentOrgId } from "../lib/store"
import { Api } from "../lib/api"
import { isEmpty } from "../lib/util"

export default function FetchDatasetsSchemas() {
  const user = useRouteLoaderData("auth")
  const [datasets] = useLoaderData()

  if (!datasets || !user) return <></>
  return <Outlet />
}

FetchDatasetsSchemas.loader = async () => {
  console.log("FetchDatasetsSchemas.loader fetching datasets and schemas")

  // If we're missing either datasets or schemas, fetch them
  if (isEmpty(Datasets.getValue()) || isEmpty(Schemas.getValue())) {
    const currentOrgId = CurrentOrgId.getValue()

    // This will happen if the user has not yet created an organization OR if
    // you reload any view. For now this is fine. It is paired with the redirect
    // in OrganizationCreate that will redirect to the previous page if an
    // organization already exists.
    if (!currentOrgId) {
      console.log("No current org id, redirecting to create")
      return redirect("/organizations/create")
    }

    const api = new Api()
    const datasets = await api.datasetsList(currentOrgId)
    const schemas = await api.schemasList()
    return [datasets, schemas]
  }

  // Otherwise use stored state
  return [Datasets.getValue(), Schemas.getValue()]
}
