import React from "react"
import { Form } from "react-router-dom"
import { Api } from "../lib/api"

import { Input, Button, VStack, HStack, Text, Image } from "@chakra-ui/react"

import Logo from "../images/logoverticallight-1.png"

import "../components/Login.css"
import GradientBox from "./GradientBox"

export default function ForgotPassword({ setLinkSent, setToEmail }) {
  const api = new Api()

  const handlePasswordReset = (event) => {
    event.preventDefault()
    const email = event.target.email.value
    console.log("Resetting password for", email)
    const resetPassword = async () => {
      console.log("Sending password reset request")
      const resp = await api.accountsSendPasswordResetLink(email)
      return resp
    }
    setToEmail(email)
    const ok = Boolean(resetPassword())
    setLinkSent(ok)
  }

  return (
    <GradientBox>
      <Image src={Logo} height="10%" alt="Blind Insight" />
      <VStack
        minWidth="100%"
        justifyContent="space-between"
        alignItems="left"
        mt="3em"
      >
        <Form onSubmit={handlePasswordReset}>
          <Text variant="subtext">Email</Text>
          <Input
            mt="1em"
            variant="account"
            id="email"
            name="email"
            type="email"
            isRequired
          />
          <Text variant="subtext"> </Text>
          <HStack justifyContent="flex-end" mt="1em">
            <Button variant="account" type="submit">
              Send Link
            </Button>
          </HStack>
        </Form>
      </VStack>
    </GradientBox>
  )
}
