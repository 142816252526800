const Containers = {
  baseStyle: {},
  variants: {
    viewStandard: {
      display: "flex",
      flexDirection: "row",
      margin: "0",
      padding: "0",
      height: "100vh",
      width: "100vw",
    },
    contentStandard: {
      width: "16em",
      padding: "1em",
      display: "flex",
      flexDirection: "column",
      bg: "Gray.500",
      overflow: "auto",
    },
    menuStandard: {
      display: "flex",
      flexDirection: "column",
      width: "14em",
      height: "100%",
      padding: "0",
      bg: "Primary.700",
      justifyContent: "flex-start",
    },
  },
}

export default Containers
