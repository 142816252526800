import React, { useMemo } from "react"
import {
  Alert,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spacer,
  Text,
  Stack,
  VStack,
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import { Form, useNavigate } from "react-router-dom"
import datasetCard from "../images/dataset-card.svg"
import { Api } from "../lib/api"
import { DatasetSchemas, CurrentOrg } from "../lib/store"
import Link from "./Link"

export default function DatasetCard({ dataset }) {
  const [datasetSchemas] = DatasetSchemas.useState()
  const [currentOrg] = CurrentOrg.useState()
  const count = useMemo(
    () => datasetSchemas[dataset.id]?.length || 0,
    [datasetSchemas, dataset],
  )
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(null)
  const api = new Api()
  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault()
    const { name, description } = event.target.elements
    try {
      const response = await api.datasetsCreate(
        name.value,
        description.value,
        currentOrg.url,
      )
      setOpen(false)
      setError(null)
      await api.datasetsList(currentOrg.id)
      console.log("Created dataset", response)
      navigate(`/datasets/${response.id}`)
    } catch (e) {
      console.log("Failed to create dataset", e)
      setError((await e.json()).detail)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setError(null)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  if (dataset === "default") {
    return (
      <Box
        bg="Gray.100"
        width="15em"
        height="15em"
        borderRadius="lg"
        boxShadow="xl"
      >
        <Box
          borderTopRadius="lg"
          display="flex"
          bg="Gray.700"
          padding="0.75em"
          justifyContent="center"
        >
          <Image width="90px" height="90px" src={datasetCard} />
        </Box>
        <Box display="flex" justifyContent="center" padding="2em">
          <Button
            variant="primary"
            onClick={handleOpen}
            rightIcon={<AddIcon />}
          >
            Create Dataset
          </Button>

          <Modal variant="mini" isOpen={open} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add a new Dataset</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {error && <Alert severity="error">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <VStack gap="4">
                    <VStack gap="1" width="full" alignItems="flex-start">
                      <Input
                        variant="primary"
                        name="name"
                        placeholder="My New Dataset"
                        status={error && "error"}
                        id="ds-name"
                        isRequired
                      />
                      <Text color="black" fontSize="xs">
                        {error || "Give your dataset a name"}
                      </Text>
                    </VStack>
                    <VStack gap="1" width="full" alignItems="left">
                      <Input
                        variant="primary"
                        name="description"
                        placeholder="This is a dataset for/of..."
                        id="ds-description"
                      />
                      <Text color="black" fontSize="xs">
                        And optional description
                      </Text>
                    </VStack>
                    <Flex width="full" alignItems="right" gap="2">
                      <Spacer />
                      <Button
                        as="Link"
                        buttonStyle="inline"
                        title="Close dialogue"
                        size="sm"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        text="Create"
                        type="submit"
                      >
                        Create
                      </Button>
                    </Flex>
                  </VStack>
                </Form>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      bg="Gray.100"
      width="15em"
      height="15em"
      borderRadius="lg"
      boxShadow="xl"
    >
      <Box
        borderTopRadius="lg"
        display="flex"
        bg="Gray.700"
        padding="0.75em"
        height="7em"
        justifyContent="center"
      >
        <Image width="90px" height="90px" src={datasetCard} />
      </Box>
      <Stack height="8em" padding="3">
        <Heading
          height="1.5em"
          mt="0"
          mb="0"
          size="md"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Link to={`/datasets/${dataset.id}`} fontWeight="bold">
            {dataset.name}
          </Link>
        </Heading>
        <Text
          height="3em"
          variant="card"
          overflow="hidden"
          textOverflow="ellipsis"
          css={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {dataset.description}
        </Text>
        <Text height="1.25em" variant="card">
          {count} schema{count > 1 ? "s" : ""}
        </Text>
      </Stack>
    </Box>
  )
}
