import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import Routes from "./App"
import reportWebVitals from "./reportWebVitals"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import CustomTheme from "./components/CustomTheme"
import env from "./lib/env"

const router = createBrowserRouter(Routes)
const root = ReactDOM.createRoot(document.getElementById("root"))
const googleClientId =
  process.env.BLINDINSIGHT_GOOGLE_CLIENT_ID || env.BLINDINSIGHT_GOOGLE_CLIENT_ID

root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <ChakraProvider theme={CustomTheme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </GoogleOAuthProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
window.Intercom("boot", {
  api_base: "https://api-iam.intercom.io",
  app_id: "gldix7ww",
  name: sessionStorage.getItem("auth.username"), // Full name
  email: sessionStorage.getItem("auth.username"), // the email for your user
  //created_at: "user.createdAt", // Signup date as a Unix timestamp
})

window.Intercom("update")
